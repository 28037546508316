import api from '@/libs/axios'
import { FilterModel, GeneratePowerpointModel, ExtraFilterCreateUpdateModel, ExtraFilterValueCreateUpdateModel, RapportActiviteModel } from '../models/rapportActivites/rapportActivite'
import { TypeGraphEnum } from '../models/enums/typeGraph'

const resource = 'RapportActivites'

export default class RapportActivitesRessource {
  
  public async repartitionAmenagementPosteByPartiesPrenantesInternes(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmenagementPosteByPartiesPrenantesInternes`, data);
    return response.data;
  }
  public async repartitionAmtEdpFamilleRisquesByTypePosteCommand(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpFamilleRisquesByTypePosteCommand`, data);
    return response.data;
  }
  public async repartitionTempsByThemeProjetPsp(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionTempsByThemeProjetPsp`, data);
    return response.data;
  }
  public async repartitionTempsByThemeProjetPst(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionTempsByThemeProjetPst`, data);
    return response.data;
  }
  public async repartitionNiveauSatisfactionConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByNiveauSatisfaction`, data);
    return response.data;
  }
  public async repartitionNiveauStressConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByNiveauStress`, data);
    return response.data;
  }
  public async repartitionRaisonVenueConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMdtRaisonVenue`, data);
    return response.data;
  }
  public async repartitionExamensCompByTypeExamenMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionExamensCompMDTByTypeExamem`, data);
    return response.data;
  }
  public async repartitionOrigineConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionOrigineConsultationMDT`, data);
    return response.data;
  }
  public async repartitionTypeVisiteConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionTypeVisiteConsultationMDT`, data);
    return response.data;
  }

  public async repartitionDossiersByMaladiePro(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionDossiersByMaladiePro`, data);
    return response.data;
  }

  public async repartitionDureeTravailConsusltationsMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionDureeTravailConsultationMDT`, data);
    return response.data;
  }

  public async repartitionActionsAdmSuiviPrecoBySecteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionActionsAdmSuiviPrecoBySecteur`, data);
    return response.data;
  }

  public async repartitionPartiesPrenantesExternesTypeActionPointInformel(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionPartiesPrenantesExternesTypeActionPointInformel`, data);
    return response.data;
  }

  public async repartitionPartiesPrenantesInternesTypeActionPointInformel(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionPartiesPrenantesInternesTypeActionPointInformel`, data);
    return response.data;
  }

  public async repartitionAmtEdpByPartiesPrenantesExternes(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpByPartiesPrenantesExternes`, data);
    return response.data;
  }
  public async repartitionAmenagementPosteByPartiesPrenantesExternesBySecteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmenagementPosteByPartiesPrenantesExternesBySecteur`, data);
    return response.data;
  }
  public async repartitionAmenagementPosteBySecteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmenagementPosteBySecteur`, data);
    return response.data;
  }
  public async repartitionEdpDossiersBySecteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionEDPBySecteur`, data);
    return response.data;
  }
  public async repartitionDossiersWithEDPByPrescripteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionDossierByPrescripteurEDP`, data);
    return response.data;
  }
  public async repartitionDossiersWithEDPByContexte(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionDossierByContexteEDP`, data);
    return response.data;
  }
  public async repartitionAmenagementPosteBySite(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmenagementPosteBySite`, data);
    return response.data;
  }
  public async repartitionAmenagementPosteByPartiesPrenantesExternesBySite(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmenagementPosteByPartiesPrenantesExternesBySite`, data);
    return response.data;
  }
  public async repartitionAmenagementPosteByPartiesPrenantesExternes(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmenagementPosteByPartiesPrenantesExternes`, data);
    return response.data;
  }
  public async repartitionAmtEdpByBranche(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpByBranche`, data);
    return response.data;
  }
  public async repartitionAmtEdpBySecteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpBySecteur`, data);
    return response.data;
  }
  public async repartitionAmtEdpBySite(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpBySite`, data);
    return response.data;
  }
  public async repartitionAmtEdpByRegion(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpByRegion`, data);
    return response.data;
  }
  public async repartitionAmtEdpByPrescripteur(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpByPrescripteur`, data);
    return response.data;
  }
  public async repartitionAmtEdpByContexte(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAmtEdpByContexte`, data);
    return response.data;
  }

  public async repartitionTempsBySousCategorieAmt(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionTempsBySousCategorieAmt`, data);
    return response.data;
  }

  public async repartitionActionsBySousCategorieAmt(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionActionsBySousCategorieAmt`, data);
    return response.data;
  }

  public async repartitionTempsByDomaine(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionTempsByDomaine`, data);
    return response.data;
  }

  public async repartitionActionsByDomaine(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionActionsByDomaine`, data);
    return response.data
  }
  public async repartitionAptitudeAvis(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionAptitudeAvis`, data);
    return response.data;
  }

  public async number(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/number`, data)
    return response.data
  }

  public async repartitionHFByDossiersWithPI(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionHFByDossiersWithPI`, data);
    return response.data;
  }

  public async repartitionActionsByTypeAction(data: RapportActiviteModel, specificId: any): Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionNombreActionsADMByTypesAction`, data);
    return response.data;
  }

  public async repartitionTempsByTypeActionADM(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionTempsByTypeActionADM`, data);
    return response.data;
  }

  public async repartitionDureeTravailConsusltationsIST(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionDureeTravailConsultationIST`, data);
    return response.data;
  }
 
  public async repartitionNbPiDouleursPsychiquesByDouleursPsychiques(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionNombrePIWithDouleursPsychiquesByDouleursPsychiques`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByOrigine(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByOrigine`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByExamensComplementaire(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByExamensComplementaire`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByRaisonsVenue(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByRaisonsVenue`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByOrientations(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByOrientations`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByTypeVisite(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByTypeVisite`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByActionsRealisees(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByActionsRealisees`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByDouleursPhysiques(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByDouleursPhysiques`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByJours(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByJours`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByMois(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByMois`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByTranchesAge(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByTranchesAge`, data);
    return response.data;
  }
  
  public async repartitionConsultationsISTByHoraires(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByHoraires`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByCSP(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByCSP`, data);
    return response.data;
  }

  public async repartitionConsultationsISTBySexe(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTBySexe`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByTypesContrats(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByTypesContrats`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByTempsTravail(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByTempsTravail`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByPrecisionsRaisonsVenue(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByPrecisionsRaisonsVenue`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByPrecisionsRaisonsVenueAT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByPrecisionsRaisonsVenueAT`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByLocalisationsDouleursOnlyAT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByLocalisationDouleursOnlyAT`, data);
    return response.data;
  }

  public async repartitionConsultationsISTByLocalisationsDouleursOnlyMPAndTMS(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsISTByLocalisationDouleursOnlyMPAndTMS`, data);
    return response.data;
  }
  
  public async exportPPT(data: GeneratePowerpointModel) : Promise<any> {
    const response = await api.post(`${resource}/exportPPT`, data, {responseType: "arraybuffer", timeout: 1980000})
    return response.data
  }

  public async extraFilters(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ) {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/filters`, {
      params: params,
    });
    return response.data;
  }

  public async extraFiltersByTypeGraph(
    typeGraph?: TypeGraphEnum,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ) {
    const params = new URLSearchParams();
    if (typeGraph) params.append("TypeGraph", typeGraph.toString());
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/filtersTypeGraph`, {
      params: params,
    });
    return response.data;
  }
  
  public async extraFilterById(id: string) : Promise<void> {
    const response = await api.get(`${resource}/filters/${id}`)
    return response.data;
  }

  public async createExtraFilter(data : ExtraFilterCreateUpdateModel) : Promise<void> {
    const response = await api.post(`${resource}/filters`, data);
    return response.data;
  }

  public async deleteExtraFilter(id: string) : Promise<void> {
    const response = await api.delete(`${resource}/filters/${id}`)
    return response.data;
  }

  public async updateExtraFilter(id: string, data : ExtraFilterCreateUpdateModel) : Promise<void> {
    const response = await api.put(`${resource}/filters/${id}`, data);
    return response.data;
  }

  public async createExtraFilterValue(id: string, data : ExtraFilterValueCreateUpdateModel) : Promise<void> {
    const response = await api.post(`${resource}/filters/${id}/valeurs`, data);
    return response.data;
  }

  public async deleteExtraFilterValue(id: string, valeurId: string) : Promise<void> {
    const response = await api.delete(`${resource}/filters/${id}/valeurs/${valeurId}`)
    return response.data;
  }

  public async updateExtraFilterValue(id: string, valueId: string, data : ExtraFilterValueCreateUpdateModel) : Promise<void> {
    const response = await api.put(`${resource}/filters/${id}/valeurs/${valueId}`, data);
    return response.data;
  }

  public async demandes(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ) {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    // if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/demandes`, {
      params: params,
    });
    return response.data;
  }

  // #040 - Consultations MDT par Point Positif
  public async repartitionPointPositifConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByPointPositif`, data);
    return response.data;
  }

  // #041 - Consultations MDT par Point Negatif
  public async repartitionPointNegatifConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByPointNegatif`, data);
    return response.data;
  }

  // #042 - Consultations MDT par Action Realisee
  public async repartitionActionRealiseeConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByActionRealisee`, data);
    return response.data;
  }

  // #043 - Consultations MDT par Orientation
  public async repartitionOrientationConsultationMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByOrientation`, data);
    return response.data;
  }

  // #044 - Consultations MDT par Douleur Psychique
  public async repartitionDouleurPsychiqueConsultationsMDT(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByDouleurPsychique`, data);
    return response.data;
  }

  // #044 - Consultations MDT par Pathologie et Soins liés au Travail
  public async repartitionConsultationsMDTByPathologieSoinTravail(data: RapportActiviteModel, specificId?: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionConsultationsMDTByPathologieSoinTravail`, data);
    return response.data;
  }
}
